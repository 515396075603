::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b1b1b1;
}
img {
  max-width: 100%;
  height: auto;
}
label {
  font-size: 14px;
  font-weight: 500;
}
.card {
  margin-bottom: 25px;
}
.card-title {
  margin-bottom: 0;
}
.placeholder-image {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.image-placeholder-text {
  margin-top: 25px;
  color: #a0a5af;
}
.upload-hints {
  color: #a0a5af;
  margin-bottom: -8px;
  margin-top: 2px;
}
.pdf-guide {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    max-height: 130px;
    width: 100%;
  }
}
.rdw-editor-wrapper {
  min-height: 300px;
  border: 1px solid #f1f1f1 !important;
  border-radius: 0.42rem;
  box-shadow: 0px 4px 30px #b5b5c340;
}
.rdw-editor-toolbar {
  padding: 1rem;
  border: none;
  border-bottom: 1px solid #f1f1f1;
  a {
    color: initial;
  }
}
.rdw-editor-main {
  padding: 0 1rem;
}
.rdw-option-wrapper {
  border: none;
}
.card-header-custom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table-custom {
  th {
    background-color: #0f1041;
    color: #fff;
    font-weight: normal !important;
  }
}
.table thead th {
  padding-top: 8px;
  padding-bottom: 8px;
}
.table {
  .image_wrapper {
    width: 40px;
    height: 40px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.table th,
.table td {
  vertical-align: middle;
}
.btn-action-group {
  display: flex;
  align-items: center;
  gap: 8px;
}
.btn-action {
  border-radius: 6px;
  padding: 4px;
  background: #f3f6f9;
  border: none;
  &.edit {
    transition: all 0.3s;
    color: #3699ff;
    &:hover {
      color: #045cb4;
    }
  }
  &.trash {
    transition: all 0.3s;
    color: #3699ff;
    &:hover {
      color: red;
    }
  }
}
// .feedback {
//   // display: none;
// }
.react-datepicker-wrapper {
  width: 100%;
}
.btn.btn-primary {
  background-color: #0f1041;
  border-color: #0f1041;
  &:hover {
    background-color: #1b1e79 !important;
    border-color: #1b1e79 !important;
  }
}
.btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text) {
  background-color: #0f1041;
  border-color: #0f1041;
}
.uni-sub-title {
  font-size: 11px;
  margin-bottom: 0;
}
.menu-text.menu-link.menu-toggle {
  pointer-events: none;
}

/*university menu*/
@media (min-width: 992px) {
  .header-menu.header-menu-layout-default .menu-nav > .menu-item > a.menu-link {
    padding: 8px 5px;
  }
}

@media (min-width: 1600px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1540px;
  }
}

@media (min-width: 1900px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1740px;
  }
}

.form-group .feedback {
  display: none;
}

/* Example Styles for React Tags*/
// .app {
//   padding: 40px;
//   text-align: center;
// }

/* Example Styles for React Tags*/

.ReactTags__tags {
  position: relative;
}

.ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #f88d8d;
  color: #fff;
  border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
  border-radius: 2px;
  display: inline-block;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  border: 1px solid #eee;
  min-width: 150px;
}

.ReactTags__editInput {
  border-radius: 1px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #63bcfd;
  color: white;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  border-radius: 2px;
}
.ReactTags__selected span.ReactTags__tag:first-child {
  margin-left: 0px;
}
.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
  top: 60px;
}
.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white;
}

.react-datepicker-popper {
  z-index: 6;
}
.sugession {
  &.form-control {
    padding: 0;
  }

  input,
  input:focus,
  input:active {
    width: 100%;
    border: none;
    padding: 7px 10px;
    line-height: 1.6;
    background: transparent;
    outline: none;
  }
  & > div {
    z-index: 100;
    div {
      padding: 5px 10px;
    }
  }
}

// React Select
.css-1okebmr-indicatorSeparator {
  display: none;
}

.modal-dialog.modal-90w {
  max-width: 70%;
}
.select.form-control {
  padding: 0;
}
.select.form-control .react-select__control {
  background: transparent;
  border: none;
}

.form-group .validation-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.9rem;
  color: #f64e60;
}
.prerequisitLevel {
  border: none;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  background: transparent;
  text-transform: uppercase;
  padding: 5px 0;
  appearance: none;
}
.select.form-control {
  height: auto;
  & > div {
    border: none;
  }
}

//Career scrollable table
.custom-table {
  thead {
    th {
      border: 1px solid #ddd;
      border-top: 1px solid #ddd;
      white-space: nowrap;
      background: #fffafa;
    }
  }

  tbody {
    td {
      white-space: nowrap;
    }
  }
}

.action-btn {
  position: relative;
  &:before {
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.sun-editor {
  position: relative;
  z-index: 0;
}

.uploader-control {
  position: relative;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  &.is-invalid {
    background-color: #f3e8e8;
    border: 1px solid #f00;
  }
}

.ReactTags__suggestions {
  z-index: 111;
}

/*Start Student profile*/
.stu-font-size {
  font-size: 20px;
}

.stu-image-width img {
  width: 150px;
  border-radius: 12px;
}

.stu-badge-color {
  color: #00a3ff;
}

.stu-circle-right {
  margin-left: 141px;
}

.stu-nav-link .nav-line-tabs .nav-item a {
  font-size: 15px;
}

.stu-nav-link .nav .show > .nav-link,
.nav .nav-link:hover:not(.disabled),
.nav .nav-link.active {
  border-bottom: 2px solid #039af1;
}
.stu-nav-link .nav .show > .nav-link,
.nav .nav-link,
.nav .nav-link {
  border-bottom: 2px solid transparent;
}
.tlink {
  min-height: 100%;
}

.stu-table thead h6 {
  color: #414abb;
}
.stu-table thead tr {
  background-color: #f5f6fc;
}
.test-score {
  background-color: #f5f6fc;
}
.test-score h6 {
  color: #414abb;
}
.stu-table tbody tr td {
  width: 50%;
}
.stu-table-data tr td {
  width: 25% !important;
}
.stu-text-bold {
  font-weight: 600 !important;
}

.border-radius {
  border-radius: 20px;
}

.stu-document-image img {
  width: 50px;
}
.student-document {
  display: flex;
  justify-content: space-around;
}
.student-table-border {
  border-bottom: 2px solid #f5f6fc;
}

.icon-section {
  display: flex;
  justify-content: right;
  align-items: center;
}

.stu-table .text-bold {
  font-weight: 600;
}

.table-image-width {
  width: 150px;
}
/*End Student profile*/
.table-checkbox .checkbox {
  display: inline-block;
}
.custom-course-bg {
  background-color: #ffa800 !important;
}

.search-wrapper {
  padding: 10px 30px 0;
  background: #f3f3f3;
  .form-group {
    margin-bottom: 0.75rem;
  }
}

.navlist-wrapper {
  display: block;
  padding: 15px;

  .nestable-item,
  .nestable-item-copy {
    margin: 5px 0 0;
    // cursor: move;

    &::marker {
      display: none;
      font-size: 0;
    }
    .menu-icon {
      display: inline-block;
      width: 30px;
      margin-right: 15px;
    }
  }
  .nestable-item .nestable-list,
  .nestable-item-copy .nestable-list {
    margin-top: 5px;
  }
}

.nastable-card {
  position: relative;
  display: flex;
  flex-direction: row;
  min-width: 0;
  word-wrap: break-word;
  background-color: #f3f3f3;
  background-clip: border-box;
  border: 1px solid #ebedf3;
  border-radius: 0.42rem;
  align-items: center;
  cursor: move;
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    min-height: 30px;
    background-color: transparent;
    width: 100%;
    border-left: 1px solid rgb(248, 248, 248);
    min-height: 35px;
    h3 {
      font-size: 13px;
      line-height: 1;
      font-weight: 500;
      margin: 0;
      padding: 5px 10px;
    }
  }
  .nestable-item-handler {
    border-right: 1px solid #dfdfdf;
    padding: 5px;
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 35px;
    cursor: move;
  }
  .collaps-action {
    border-right: 1px solid #dfdfdf;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 35px;
    cursor: pointer;
  }
}

.card.card-navlist > .card-body.no-padding {
  padding: 0;
}
.label-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.right-input {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}
.select-menu-type {
  display: inline-block;
  margin-right: 15px;
}

.icon-group {
  .card.card-custom {
    background: transparent;
    box-shadow: none !important;
    .card-header {
      min-height: 20px;
      padding: 0;
      .card-title {
        font-size: 1rem;
        font-weight: 400;
        color: #3f4254;
        .card-label {
          font-size: 1rem;
          font-weight: 400;
          color: #3f4254;
        }
      }
    }
    .card-body {
      text-align: left;
      padding: 0;

      button {
        text-align: left;
        padding: 0;
        margin-top: 10px;
        width: auto;
        border: none;
      }
      img {
        max-height: 50px;
      }
    }
  }
}

.is-invalid div[class$="-control"] {
  border-color: #f00;
}

.parmission-options {
  font-size: 16px;
  .rct-title,
  .rct-text {
    font-size: 16px;
  }
  .rct-collapse *,
  .rct-node-icon * {
    width: 24px;
    font-size: 24px;
  }
  .rct-checkbox * {
    width: 20px;
    font-size: 20px;
  }

  .rct-node {
    .rct-node-icon {
      display: none;
    }
    .rct-text {
      background: #f3f3f3;
      border-radius: 5px;
      padding: 5px;
      margin-top: 5px;
    }
    .rct-node-leaf {
      margin-left: 20px;
      .rct-text {
        padding-left: 5px;
      }
      .rct-collapse {
        display: none;
      }
    }
  }
}

.columnActions.dropdown-menu {
  padding: 5px;
}
.action-nav {
  display: block;
  text-align: center;
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    display: flex;
    justify-content: center;
    margin-bottom: 2px;
    button {
      border: none;
      width: 100%;
      padding: 8px 15px;
      display: flex;
      gap: 10px;
      justify-content: center;
      // background-color: #3699ff33;
      color: #444;
      -webkit-transition: all 300ms ease-in;
      -moz-transition: all 300ms ease-in;
      -ms-transition: all 300ms ease-in;
      -o-transition: all 300ms ease-in;
      transition: all 300ms ease-in;
      &:hover {
        // background-color: #3699ff;
        color: #3699ff;
      }
      &.btn-delete {
        // background: #ffd7d7;
        &:hover {
          // background-color: #f00;
          color: #f00;
        }
      }
      i {
        color: inherit;
      }
    }
  }
}

.filterForm {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 15px;
  .status-filter {
    margin-right: auto;
    .btn {
      display: inline-flex;
      border: none;
      background: #e7e7e7;
      margin-right: 5px;
      padding: 0;
      font-size: 13px;
      line-height: 1;
      border-radius: 3px;
      overflow: hidden;
      -webkit-transition: all 300ms ease-in;
      -moz-transition: all 300ms ease-in;
      -ms-transition: all 300ms ease-in;
      -o-transition: all 300ms ease-in;
      transition: all 300ms ease-in;
      &-all {
        background: #369bffa6;

        &.active,
        &:hover,
        &:active {
          background: #3699ff;
        }
      }
      &-published {
        background: #399155a9;

        &.active,
        &:hover,
        &:active {
          background: #399155;
        }
      }
      &-draft {
        background: #484848a1;

        &.active,
        &:hover,
        &:active {
          background: #484848;
        }
      }
      &-trashed {
        background: #f964649d;

        &.active,
        &:hover,
        &:active {
          background: #f96464;
        }
      }
      &-pending {
        background: #ffc312;

        &.active,
        &:hover,
        &:active {
          background: #f79f1f;
        }
      }
      span {
        padding: 5px;
        line-height: inherit;
        font-size: inherit;
        color: #fff;
      }
      strong {
        font-size: inherit;
        padding-left: 5px;
        background: rgba(0, 0, 0, 0.2);
        padding: 5px;
        line-height: inherit;
        color: #ffffff;
        font-weight: 500;
      }
    }
  }
}
//news category
.FixedHeightContainer {
  height: 150px;
  overflow: auto;
}
.Content {
  // height: 145px;
  overflow: auto;
}

//table comment icon
.left-margin {
  margin-left: -5px;
}
.iconClass {
  position: relative;
}
.iconClass span {
  position: absolute;
  top: 0px;
  right: 0px;
  display: block;
}

//actions for table
.nastable-card-table {
  position: relative;
  text-align: center;
  flex-direction: row;
  min-width: 0;
  word-wrap: break-word;
  align-items: center;
}

// news category list
.news-category-list {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
    margin-bottom: 5px;
    span {
      margin-right: 5px;
    }
    ul {
      padding-left: 15px;
    }
  }
}
// Menu Module Permission
.module-permission-list {
  margin: 0;
  padding: 0;
}

.module-collapse {
  padding: 6px 9px;
  border-radius: 5px;
  margin-left: 5px;
}

.modulePermission-sidebar {
  padding: 0;

  ul li {
    padding: 8px;
    font-size: 14px;
  }
}
.module-text {
  background-color: #fdebd2;
  padding: 8px;
  margin-left: -21px;
}
.module-all-permission {
  font-size: 16px;
}
.module-permission-table {
  tr td label {
    font-size: 16px;
  }
}
.bootstrap-table-height {
  min-height: 250px;
}

.content-elipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.password-icon {
  float: right;
  position: relative;
  top: -30%;
  left: -2%;
  cursor: pointer;
}
.password_error {
  margin-right: 20px;
  top: -45%;
}
.password_touched {
  margin-right: 20px;
}

.roles-name {
  ul {
    padding-left: 0;
    li {
      list-style-type: none;
      font-size: 15px;
      font-weight: 500;
      padding: 10px 15px;
      border-left: 2px solid transparent;
      margin-bottom: 4px;
    }
    li:hover {
      background-color: #f5f5fb;
      border-radius: 5px;
      border-left: 2px solid #2c5cc5;
      cursor: pointer;
    }
    .active {
      background-color: #f5f5fb;
      border-radius: 5px;
      border-left: 2px solid #2c5cc5;
      cursor: pointer;
    }
  }
}

.uniModule-permission {
  ul {
    padding-left: 0;
    li {
      list-style-type: none;
      float: left;
    }
  }
}

//form builder cursor
.form-builder .card.card-custom > .card-header {
  cursor: move;
}

//custom slug generator button
.slug-generate {
  float: right;
  background-color: white;
  margin-right: -5px;
  margin-top: -38px;
  position: relative;
  cursor: pointer;

  i {
    color: rgb(0, 0, 0);
    background-color: white;
  }
}

//news filter width

.search-field {
  width: 13.5%;
}

.template-preview {
  cursor: pointer;
}
.template-preview::first-letter {
  text-transform: capitalize;
}

// Auto Complete select box
.autoComplete {
  cursor: pointer;
}

//color template of lead
.color-template {
  border: 2px solid rgb(32, 31, 31) !important;
  border-radius: 6px;
}

// font change after parse in lead
.parse-text > * {
  font-size: 13px !important;
  font-weight: 400;
}
.parse-text h1 .mark {
  background-color: #fff !important;
}
//email template form field name on modal
.form-names {
  padding: 1.3rem 0.75rem !important;
}
.table-custom {
  min-height: 250px;
  thead {
    th {
      vertical-align: top !important;
      white-space: nowrap;
      background: #ffffff;
      color: #7e8299;
      font-weight: 600 !important;
      white-space: nowrap;
      border-top: 1px solid#EBEDF3 !important;
    }
  }

  tbody {
    td {
      vertical-align: top !important;
      color: #7e8299;
      font-weight: 500;
      white-space: nowrap;
    }
  }
}
.earning-edit-icon {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: 15px;
  top: 15px;
  transition: 0.2s ease-out;
}
.mentor-earning:hover .earning-edit-icon {
  visibility: visible;
  opacity: 1;
}
// no data start
.empty_list_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  .empty_description {
    max-width: 640px;
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    color: #5e6278;
    color: #b1b1b1;
  }
}
.empty_content_wrapper {
  padding: 40px;
  border-top: 1px solid#EBEDF3 !important;
}
//no data end
.change_password_alert {
  background: #fffade;
  padding: 10px;
  p,
  i {
    font-size: 11px;
  }
}

.country_stats_wrapper {
  font-size: 13px;
  line-height: 18px;
  color: #12344d;
  .country_stats_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    padding: 4px;
  }
  ul {
    list-style: none;
    // display: inline-block;
    height: 100%;
    max-height: 450px;
    min-height: 200px;
    overflow: auto;
    &:hover::-webkit-scrollbar-thumb {
      background: rgb(202, 202, 202);
    }
    &::-webkit-scrollbar {
      width: 5px;
      background: transparent;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 10px;
      cursor: pointer;
    }
    li {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 0;
      border-bottom: 1px solid #ebeff3;
      padding-right: 4px;
      margin-left: -35px;
      .line {
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 0%;
        height: 2px;
        background-color: #2c5cc5;
      }
    }
  }
}

//dashboard chart
.chart_graph_wrapper {
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}
.chart_info_lists {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 20px;
}
.chart_info_item_title {
  font-size: 13px;
  line-height: 18px;
  color: #475867;
}
.chart_info_item_num_wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  svg {
    color: #27ae60;
  }
  .down {
    color: #eb5757;
  }
}
.chart_info_item_num {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #12344d;
}
.chart_wrapper {
  // padding: 14px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
}
.num_percentage_wrapper {
  display: flex;
  align-items: center;
  font-size: 12px;
  gap: 3px;
}

//dynamic table stat
.custom-table-count {
  border-radius: 10px;
  border: 1px dashed #e4e6ef;
  cursor: pointer;
  .custom-table-text {
    text-align: center;
    .count {
      font-size: 26px;
      color: #d2d3d8;
      font-weight: 600;
      margin: 0;
      padding: 0;
    }

    .name {
      font-size: 18px;
      font-weight: 500;
      margin: 0;
      padding: 0;
      color: #454859;
    }
  }
}
.custom-table-count:hover {
  border: 1px dashed #00a3ff;
  .count {
    color: #00a3ff;
  }
}
.active_bar {
  height: 6px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.all {
  background: #3699ff !important;
}
.published {
  background-color: #399155 !important;
}
.draft {
  background: #484848 !important;
}
.trashed {
  background: #f96464 !important;
}
.pending {
  background: #f79f1f !important;
}

.all_border {
  border: 1px solid#3699ff !important;
}
.published_border {
  border: 1px solid#399155 !important;
}
.draft_border {
  border: 1px solid#484848 !important;
}
.trashed_border {
  border: 1px solid#f96464 !important;
}
.pending_border {
  border: 1px solid#f79f1f !important;
}

.all_active {
  color: #3699ff !important;
}
.published_active {
  color: #399155 !important;
}
.draft_active {
  color: #484848 !important;
}
.trashed_active {
  color: #f96464 !important;
}
.pending_active {
  color: #f79f1f !important;
}

.dynamic_filter {
  margin-bottom: 50px;
  margin-top: 10px;
  h4 {
    margin-left: 5px;
  }
}

// show countery wise mobile number and flag
.show_country_flag_wrpper {
  .react-tel-input {
    pointer-events: none;
    .form-control {
      border: 0 !important;
      padding-left: 30px !important;
      width: 170px !important;
      color: #7e8299 !important;
      font-weight: 500 !important;
    }
    .flag-dropdown {
      border: 0 !important;
      background-color: transparent !important;

      .arrow {
        display: none;
      }
    }
  }
}

.pass_error {
  border: 1px solid red !important;
  border-radius: 5px;
}

.breadcrumbs_btn {
  position: relative;
}

.breadcrumbs_btn li:last-child {
  position: absolute;
  right: 500;
  top: 0;
  // width: 50%;
}
.zodit-wysiwyg ul > li {
  list-style-image: url("assets/list-icon.png");
}
.zodit-wysiwyg table tr td > li {
  list-style-image: url("assets/list-icon.png");
}

//disable link or others
.disabled {
  pointer-events: none;
  cursor: default;
}

.table-profile {
  display: flex;
  justify-content: left;
  align-items: center;
  .profile-image {
    flex-shrink: 0;
    border-radius: 5px;
    height: 50px;
    width: 50px;
    overflow: hidden;
    object-fit: contain;
    margin-right: 10px;
    border: 1px solid #eee;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .profile-name {
    display: block;
    font-size: 13px;
    font-weight: 500;
  }
  .profile-designation {
    display: block;
    font-size: 12px;
    color: #777;
  }
  .profile-subDesignation {
    display: block;
    font-size: 12px;
    color: #777;
  }
  .profile-email {
    display: block;
    font-size: 12px;
    color: #777;
  }
}
//news category
.FixedHeightCounsellor {
  min-height: 70px;
  max-height: 300px;
  overflow: auto;
}

.form_builder_border {
  border: 2px solid transparent;
  border-radius: 10px;
  padding: 3px;
  &:hover {
    border: 2px solid rgb(119, 119, 240);
    transition: 0.5s ease-out;
  }
}

.form-action-icon {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: 5px;
  top: 5px;
  transition: 0.2s ease-out;
}
.form_builder_border:hover .form-action-icon {
  visibility: visible;
  opacity: 1;
}

.line_clamp_1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
