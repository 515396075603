.filesInput {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  padding: 160px 0px 105px 35%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
  opacity: 0;
  cursor: pointer;
}
.inputPlace {
  width: 96%;
  height: 92%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 2%;
  top: 10px;
  padding-top: 40px;
  z-index: 0;
  outline: 2px dashed #92b0b3;
}
.inputPlace h5 {
  border: 1px dashed #ddd;
  border-radius: 3px;
  padding: 5px 10px;
}
.filesInput:focus {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  border: 1px solid #92b0b3;
}
.files {
  position: relative;
}
.files:after {
  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: "";
  background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}
.colorBg {
  background-color: #fff;
}
.files:before {
  position: absolute;
  bottom: 10px;
  left: 0;
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  content: " or drag it here. ";
  display: block;
  margin: 0 auto;
  color: #2ea591;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}
.formGroup {
  padding: 10px;
  border: 1px solid #ddd;
  background: #fff;
  position: relative;
}
.title {
  font-size: 16px;
  margin-top: 15px;
  padding: 5px;
  text-transform: uppercase;
}
.uploadTable {
  display: table;
  margin-top: 5px;
  width: 100%;
  border-collapse: collapse;
}

.uploadTable th {
  background: #00173421;
  border: 1px solid #58585821;
  padding: 5px 15px;
  color: #000;
}
.uploadTable td {
  padding: 5px;
  font-size: 14px;
  background: transparent;
  border: 1px solid #ddd;
}
.btn_remove {
  border: none;
  background: #ee2e20;
  border-radius: 3px;
  padding: 5px 7px 5px;
  color: #fff;
  line-height: 1;
  font-size: 12px;
  line-height: 1;
  display: inline-block;
}
.filesList {
  display: block;
}
.cftable {
  display: table;
  width: 100%;
  border-collapse: collapse;
}
.cftable th {
  background: #e9e9e9;
  padding: 4px 8px;
  border: 1px solid #e9e9e9;
}
.cftable td {
  border: 1px solid #e9e9e9;
  padding: 4px 8px;
}
.fileName {
  border: 1px solid #ddd;
  border-radius: 2px;
  padding: 5px;
  font-size: 13px;
  list-style: 20px;
  width: 100%;
  min-width: 200px;
  &:focus,
  &:focus-visible {
    border-color: #4f99f9;
    outline: none;
  }
}
.shorter {
  display: block;
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.remove_btn {
  background: #ee2e20;
  color: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  border: none;
}
.remove_btn i {
  color: #fff;
  line-height: 16px;
  font-size: 13px;
}
.textcenter {
  text-align: center;
}
.imageLink {
  color: #333;
}
.uplaodBtn {
  border: none;
  background: #3699ff;
  color: #fff;
  padding: 2px 10px 4px;
  text-align: center;
  border-radius: 3px;
  margin-top: 10px;
  font-size: 13px;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.uplaodBtn:hover,
.uplaodBtn:focus,
.uplaodBtn:active {
  background: rgb(9, 88, 168);
}
.download_btn {
  display: inline-block;
  background: #1b96ff;
  border: none;
  border-radius: 3px;
  padding: 3px 7px 5px;
  color: #fff;
  line-height: 1;
  font-size: 12px;
  margin-right: 5px;
}
.download_btn i,
.btn_remove i {
  color: inherit;
  font-size: inherit;
}
.text-center {
  text-align: center;
}
