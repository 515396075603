.button {
  background: transparent;
  text-align: center;
  display: flex;
  cursor: pointer;
  align-items: center;
  position: relative;
  padding: 10px;
  border: 1px solid #ddd;
}
.imageArea {
  width: 100%;
  height: 100%;
  min-height: 100px;
  position: relative;
  text-align: center;
  font-size: 36px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filterArea {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  font-size: 36px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon {
  font-size: inherit;
  font-size: 144px;
}
.selected {
  background: #d8ffc8;
  border: 1px solid #00bd1c;
  position: relative;
  text-align: center;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 10px;
  position: relative;
}
.selected::after {
  content: "✓";
  position: absolute;
  background: #44b772;
  width: 20px;
  height: 20px;
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
  top: 5px;
  right: 5px;
  padding: 3px;
  color: #fff;
  border-radius: 50%;
}
.name {
  font-size: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  padding: 5px;
  color: #fff;
}
