span.required {
  color: #f00f00;
}

.pagination {
  gap: 8px;
}
.page-link {
  border-radius: 5px;
}
.dropdown-toggle {
  padding: 5px;
  width: 60px;
}

.error {
  color: #f00f00;
}
