.button {
  background: transparent;
  border: none;
  width: 100%;
  text-align: center;
  padding: 10px;
  border: none;
  cursor: pointer;
  align-items: center;
}
.mediaColumn {
  padding: 5px;
}
.modalBody {
  height: 600px;
  max-height: 60vh;
  overflow: auto;
}
.filterArea {
  width: 100%;
}
.icon {
  font-size: 45px;
  line-height: 1;
}
.upclsIcon {
  -moz-transform: translateX(5px) translateY(-5px);
  -webkit-transform: translateX(5px) translateY(-5px);
  -o-transform: translateX(5px) translateY(-5px);
  -ms-transform: translateX(5px) translateY(-5px);
  transform: translateX(5px) translateY(-5px);
}
.modalTitle {
  margin: 5px 0 0;
}
.noRecord {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #f00;
  font-size: 14px;
  padding-top: 15px;
}
.removeItem {
  position: absolute;
  right: 10px;
  top: 6px;
  background: #fff;
  border: none;
  padding: 8px;
  color: rgb(83, 83, 83);
  font-size: 20px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 1;
  -webkit-transition: all 300ms ease-in;
  -moz-transition: all 300ms ease-in;
  -ms-transition: all 300ms ease-in;
  -o-transition: all 300ms ease-in;
  transition: all 300ms ease-in;
  &:hover {
    background: #e47c27;
    color: #fff;
  }
}
