.date-range-picker {
  .rdrDateRangePickerWrapper {
    .rdrDefinedRangesWrapper {
      button,
      .rdrInputRanges {
        background-color: rgb(220, 223, 223) !important;
      }
      background-color: rgb(220, 223, 223) !important;
    }
    .rdrCalendarWrapper {
      background-color: rgb(220, 223, 223) !important;
      .rdrDateDisplayWrapper {
        background-color: rgb(220, 223, 223) !important;
      }
    }
  }
  .calendar-footer {
    background-color: rgb(220, 223, 223) !important;
    max-width: 100%;
    display: flex;
  }
}

.buttonColor {
  background-color: rgb(220, 223, 223) !important;
}
