.loading_box {
  position: relative;
  width: 100%;
  height: 5px;
  overflow: hidden;
  background: #dde9e3;
}
.loader {
  width: 50%;
  height: 100%;
  position: absolute;
  background: linear-gradient(45deg, #64c283, #1aa861);
  left: 0%;
}
.loader {
  left: -100%;
  animation: load 1s linear infinite;
}

@keyframes load {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
